<template>
  <div class="container1">
    <!-- 顶部标题区 -->
   <!-- <div class="tab-div">
      <img
        class="close-icon"
        src="@/assets/close.png"
        @click="$router.push('/')"
      />
      <span class="tab-title">健康服务</span>
    </div> -->
    <!-- 头部banner -->
    <div class="top-div">
      <img class="top-bg" src="@/assets/bg.png" />
      <p class="top-t1">健康服务</p>
      <p class="top-t2">守护您的健康</p>
    </div>
    <!-- 选项条 -->
    <div class="list-div">
      <router-link to="/ShJb">
        <div class="list-li">
          <span>计步</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/ShDl">
        <div class="list-li">
          <span>锻炼</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/ShJrhd">
        <div class="list-li">
          <span>今日活动</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/ShCkdljl">
        <div class="list-li">
          <span>查看锻炼记录</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/ShXx">
        <div class="list-li">
          <span>消息</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/ShTq">
        <div class="list-li">
          <span>天气</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/ShRl">
        <div class="list-li">
          <span>日历</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/ShJsqi">
        <div class="list-li">
          <span>计算器</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/ShNzhong">
        <div class="list-li">
          <span>闹钟</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/ShMb">
        <div class="list-li">
          <span>秒表</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/ShSdt">
        <div class="list-li">
          <span>手电筒</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/ShJsq">
        <div class="list-li">
          <span>计时器</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      
      <router-link to="/ShZsj">
        <div class="list-li">
          <span>找手机</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/ShZfb">
        <div class="list-li">
          <span>支付宝</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
	import "@/assets/css/page_ocss.less"
export default {
  name: 'IndexPage',
}
</script>


