<template>
  <div class="container">
    <!-- 顶部标题区 -->
  <!--  <div class="tab-div">
      <img
        class="close-icon"
        src="@/assets/close.png"
        @click="$router.push('/kuaisushangshou')"
      />
      <span class="tab-title">快速上手</span>
    </div> -->
    <!-- 内容区域 -->
    <div class="info-div">
      <p class="info-title">配对连接设备</p>
      <img class="img1" src="@/assets/peiduilianjieshebei.png" />
    
    </div>
  </div>
</template>

<script>
	import "@/assets/css/page_tcss.less";
export default {
  name: 'IndexPage',
}
</script>

