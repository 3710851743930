<template>
  <div class="container1">
    <!-- 顶部标题区 -->
   <!-- <div class="tab-div">
      <img
        class="close-icon"
        src="@/assets/close.png"
        @click="$router.push('/')"
      />
      <span class="tab-title">健康服务</span>
    </div> -->
    <!-- 头部banner -->
    <div class="top-div">
      <img class="top-bg" src="@/assets/bg.png" />
      <p class="top-t1">健康服务</p>
      <p class="top-t2">守护您的健康</p>
    </div>
    <!-- 选项条 -->
    <div class="list-div">
      <router-link to="/JianClxl">
        <div class="list-li">
          <span>测量心率</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/JianCltw">
        <div class="list-li">
          <span>体温监测</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/JianClxybhd">
        <div class="list-li">
          <span>测量血氧饱和度</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/JianClxyy">
        <div class="list-li">
          <span>测量血压</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/JianJcsm">
        <div class="list-li">
          <span>监测睡眠</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/JianJcyl">
        <div class="list-li">
          <span>检测压力</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/JianHxljc">
        <div class="list-li">
          <span>呼吸率检测</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/JianHxxl">
        <div class="list-li">
          <span>呼吸训练</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/JianJztx">
        <div class="list-li">
          <span>久坐提醒</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/JianHstx">
        <div class="list-li">
          <span>喝水提醒</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/JianYytx">
        <div class="list-li">
          <span>用药提醒</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
	import "@/assets/css/page_ocss.less"
export default {
  name: 'IndexPage',
}
</script>

