<template>
  <div class="container">
    <!-- 顶部标题区 -->
   <!-- <div class="tab-div">
      <img
        class="close-icon"
        src="@/assets/close.png"
        @click="$router.push('/bianjieshenghuo')"
      />
      <span class="tab-title">健康服务</span>
    </div> -->
    <!-- 内容区域 -->
    <div class="info-div">
      <p class="info-title">今日活动</p>
      <p class="info-p">
<img class="img1" src="@/assets/jinrihuodong.png" />    
      </p>
     
    </div>
  </div>
</template>

<script>
	import "@/assets/css/page_tcss.less";
export default {
  name: 'IndexPage',
}
</script>

