import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/anjianpingmukongzhi',
    name: 'anjianpingmukongzhi',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "anjianpingmukongzhi" */ '../views/anjianpingmukongzhi.vue')
  },
  {
    path: '/bianjieshenghuo',
    name: 'bianjieshenghuo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "bianjieshenghuo" */ '../views/bianjieshenghuo.vue')
  },
  {
    path: '/chongdian',
    name: 'chongdian',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "chongdian" */ '../views/chongdian.vue')
  },
  {
    path: '/GdCqycz',
    name: 'GdCqycz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdCqycz" */ '../views/GdCqycz.vue')
  },
  {
    path: '/GdGhbp',
    name: 'GdGhbp',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdGhbp" */ '../views/GdGhbp.vue')
  },
  {
    path: '/GdGj',
    name: 'GdGj',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdGj" */ '../views/GdGj.vue')
  },
  {
    path: '/GdGysb',
    name: 'GdGysb',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdGysb" */ '../views/GdGysb.vue')
  },
  {
    path: '/GdKpsz',
    name: 'GdKpsz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdKpsz" */ '../views/GdKpsz.vue')
  },
  {
    path: '/GdLdtj',
    name: 'GdLdtj',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdLdtj" */ '../views/GdLdtj.vue')
  },
  {
    path: '/GdLssz',
    name: 'GdLssz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdLssz" */ '../views/GdLssz.vue')
  },
  {
    path: '/GdPms',
    name: 'GdPms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdPms" */ '../views/GdPms.vue')
  },
  {
    path: '/GdRqysj',
    name: 'GdRqysj',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdRqysj" */ '../views/GdRqysj.vue')
  },
  {
    path: '/GdSj',
    name: 'GdSj',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdSj" */ '../views/GdSj.vue')
  },
  {
    path: '/GdSssz',
    name: 'GdSssz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdSssz" */ '../views/GdSssz.vue')
  },
  {
    path: '/GdXpsj',
    name: 'GdXpsj',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdXpsj" */ '../views/GdXpsj.vue')
  },
  {
    path: '/GdYltj',
    name: 'GdYltj',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdYltj" */ '../views/GdYltj.vue')
  },
  {
    path: '/GdYybb',
    name: 'GdYybb',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdYybb" */ '../views/GdYybb.vue')
  },
  {
    path: '/',
    name: 'GdYybb',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "GdYybb" */ '../views/GdYybb.vue')
  },
  {
    path: '/JianClxl',
    name: 'JianClxl',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "JianClxl" */ '../views/JianClxl.vue')
  },
  {
    path: '/JianCltw',
    name: 'JianCltw',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "JianCltw" */ '../views/JianCltw.vue')
  },
  {
    path: '/JianClxybhd',
    name: 'JianClxybhd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "JianClxybhd" */ '../views/JianClxybhd.vue')
  },
  {
    path: '/JianClxyy',
    name: 'JianClxyy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "JianClxyy" */ '../views/JianClxyy.vue')
  },
  {
    path: '/JianHstx',
    name: 'JianHstx',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "JianHstx" */ '../views/JianHstx.vue')
  },
  {
    path: '/JianHxljc',
    name: 'JianHxljc',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "JianHxljc" */ '../views/JianHxljc.vue')
  },
  {
    path: '/JianHxxl',
    name: 'JianHxxl',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "JianHxxl" */ '../views/JianHxxl.vue')
  },
  {
    path: '/JianJcsm',
    name: 'JianJcsm',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "JianJcsm" */ '../views/JianJcsm.vue')
  },
  {
    path: '/JianJcyl',
    name: 'JianJcyl',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "JianJcyl" */ '../views/JianJcyl.vue')
  },
  {
    path: '/JianJztx',
    name: 'JianJztx',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "JianJztx" */ '../views/JianJztx.vue')
  },
  {
    path: '/JianYytx',
    name: 'JianYytx',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "JianYytx" */ '../views/JianYytx.vue')
  },
  {
    path: '/ShCkdljl',
    name: 'ShCkdljl',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShCkdljl" */ '../views/ShCkdljl.vue')
  },
  {
    path: '/ShDl',
    name: 'ShDl',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShDl" */ '../views/ShDl.vue')
  },
  {
    path: '/ShJb',
    name: 'ShJb',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShJb" */ '../views/ShJb.vue')
  },
  {
    path: '/ShJrhd',
    name: 'ShJrhd',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShJrhd" */ '../views/ShJrhd.vue')
  },
  {
    path: '/ShJsq',
    name: 'ShJsq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShJsq" */ '../views/ShJsq.vue')
  },
  {
    path: '/ShJsqi',
    name: 'ShJsqi',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShJsqi" */ '../views/ShJsqi.vue')
  },
  {
    path: '/ShMb',
    name: 'ShMb',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShMb" */ '../views/ShMb.vue')
  },
  {
    path: '/ShNzhong',
    name: 'ShNzhong',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShNzhong" */ '../views/ShNzhong.vue')
  },
  {
    path: '/ShRl',
    name: 'ShRl',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShRl" */ '../views/ShRl.vue')
  },
  {
    path: '/ShSdt',
    name: 'ShSdt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShSdt" */ '../views/ShSdt.vue')
  },
  {
    path: '/ShTq',
    name: 'ShTq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShTq" */ '../views/ShTq.vue')
  },
  {
    path: '/ShXx',
    name: 'ShXx',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShXx" */ '../views/ShXx.vue')
  },
  {
    path: '/ShZfb',
    name: 'ShZfb',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShZfb" */ '../views/ShZfb.vue')
  },
  {
    path: '/ShZsj',
    name: 'ShZsj',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ShZsj" */ '../views/ShZsj.vue')
  },
  {
    path: '/gengduozhidao',
    name: 'gengduozhidao',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "gengduozhidao" */ '../views/gengduozhidao.vue')
  },
  {
    path: '/hxsb',
    name: 'hxsb',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hxsb" */ '../views/hxsb.vue')
  },
  {
    path: '/jiankangfuwu',
    name: 'jiankangfuwu',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "jiankangfuwu" */ '../views/jiankangfuwu.vue')
  },
  {
    path: '/jiankangguanli',
    name: 'jiankangguanli',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "jiankangguanli" */ '../views/jiankangguanli.vue')
  },
  {
    path: '/kuaisushangshou',
    name: 'kuaisushangshou',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "kuaisushangshou" */ '../views/kuaisushangshou.vue')
  },
  {
    path: '/pdljsb',
    name: 'pdljsb',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pdljsb" */ '../views/pdljsb.vue')
  },
  {
    path: '/peidaishoubiao',
    name: 'peidaishoubiao',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "peidaishoubiao" */ '../views/peidaishoubiao.vue')
  },
  {
    path: '/shoushicaozuo',
    name: 'shoushicaozuo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "shoushicaozuo" */ '../views/shoushicaozuo.vue')
  },
  {
    path: '/wgjs',
    name: 'wgjs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "wgjs" */ '../views/wgjs.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
