import { render, staticRenderFns } from "./GdGhbp.vue?vue&type=template&id=97b6bde4"
import script from "./GdGhbp.vue?vue&type=script&lang=js"
export * from "./GdGhbp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports