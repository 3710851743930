<template>
  <div class="container1">
    <!-- 顶部标题区 -->
  <!--  <div class="tab-div">
      <img
        class="close-icon"
        src="@/assets/close.png"
        @click="$router.push('/')"
      />
      <span class="tab-title">健康服务</span>
    </div> -->
    <!-- 头部banner -->
    <div class="top-div">
      <img class="top-bg"  src="@/assets/bg.png" />
      <p class="top-t1">健康服务</p>
      <p class="top-t2">守护您的健康</p>
    </div>
    <!-- 选项条 -->
    <div class="list-div">
      <router-link to="/wgjs">
        <div class="list-li">
          <span>外观介绍</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/hxsb">
        <div class="list-li">
          <span>唤醒手表</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/pdljsb">
        <div class="list-li">
          <span>配对连接设备</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/peidaishoubiao">
        <div class="list-li">
          <span>佩戴手表</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/anjianpingmukongzhi">
        <div class="list-li">
          <span>按键及屏幕控制</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/shoushicaozuo">
        <div class="list-li">
          <span>手势操作</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/chongdian">
        <div class="list-li">
          <span>充电</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import '@/assets/css/page_ocss.less'
export default {
  name: 'IndexPage',
}
</script>

