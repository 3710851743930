<template>
  <div class="container">
    <!-- 顶部标题区 -->
   <!-- <div class="tab-div">
      <img
        class="close-icon"
        src="@/assets/close.png"
        @click="$router.push('/')"
      />
      <span class="tab-title">健康服务</span>
    </div> -->
    <!-- 头部banner -->
    <div class="top-div">
      <img class="top-bg" src="@/assets/bg.png" />
      <p class="top-t1">健康服务</p>
      <p class="top-t2">守护您的健康</p>
    </div>
    <!-- 选项条 -->
    <div class="list-div">
      <router-link to="/fuwu">
        <div class="list-li">
          <span>尊享守护服务</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/">
        <div class="list-li">
          <span>健康档案</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexPage',
}
</script>

<style lang="less" scoped>
.container {
  position: relative;
  // 顶部标题区
  .tab-div {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    width: 100%;
    line-height: 110px;
    text-align: center;
    font-weight: bold;
    font-size: 38px;
    .close-icon {
      position: fixed;
      left: 45px;
      height: 50px;
      top: 30px;
    }
  }
  // 头部banner
  .top-div {
    position: relative;
    margin-top: 0px;
    .top-bg {
      position: relative;
      width: 100%;
    }
    p {
      color: white;
      position: absolute;
      left: 45px;
    }
    .top-t1 {
      font-size: 28px;
      top: 50px;
    }
    .top-t2 {
      top: 90px;
      font-size: 38px;
    }
  }
  // 选项条
  .list-div {
    position: relative;
    border-radius: 25px;
    background: white;
    top: -50px;
    padding: 50px 45px;
    .list-li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 100px;
      border-bottom: 1px solid #eceff6;
      color: black;
      img {
        // 选项的右箭头高度
        height: 40px;
      }
    }
    a:last-child .list-li:last-child {
      // 取消最后一个选项的边框下划线
      border: none;
    }
  }
}
</style>
