<template>
  <div class="container">
    <!-- 顶部标题区 -->
    <!-- <div class="tab-div">
      <img
        class="close-icon"
        src="@/assets/close.png"
        @click="$router.push('/')"
      />
      <span class="tab-title">帮助与客服</span>
    </div> -->
    <!-- 头部banner -->
    <div class="top-div">
      <img class="top-bg" src="@/assets/img.png" />
      <p class="top-t1">完成每日目标</p>
      <p class="top-t2">助力健康生活</p>
    </div>
    <span class="card-title">用户指南</span>
    <div class="card-box">
      <!-- to 后面跟跳转的页面，/ 代表返回本页 -->
      <router-link to="/kuaisushangshou">
        <div class="card-li">
          <img class="card-icon" src="@/assets/jiankang.png" />
          <p class="card-p">快速上手</p>
        </div>
      </router-link> 
       
     
      <router-link to="/jiankangguanli">
        <div class="card-li">
          <img class="card-icon" src="@/assets/jiating.png" />
          <p class="card-p">健康管理</p>
        </div>
      </router-link>
      <router-link to="/bianjieshenghuo">
        <div class="card-li">
          <img class="card-icon" src="@/assets/sanyecao.png" />
          <p class="card-p">便捷生活</p>
        </div>
      </router-link>
      <router-link to="/gengduozhidao">
        <div class="card-li">
          <img class="card-icon" src="@/assets/geren.png" />
          <p class="card-p">更多指导</p>
        </div>
      </router-link>
<!--      <router-link to="/">
        <div class="card-li">
          <img class="card-icon" src="@/assets/huiyuan.png" />
          <p class="card-p">管理会员</p>
        </div>
      </router-link>
      <router-link to="/jiankangfuwu">
        <div class="card-li">
          <img class="card-icon" src="@/assets/fuwu.png" />
          <p class="card-p">健康服务</p>
        </div>
      </router-link> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexPage',
}
</script>

<style lang="less" scoped>
.container {
  position: relative;
  background: #f7f7f7;
  min-height: 100vh;
  padding: 0 30px;
  // 顶部标题区
  .tab-div {
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    width: 100%;
    line-height: 110px;
    text-align: center;
    font-weight: bold;
    font-size: 38px;
    .close-icon {
      position: fixed;
      left: 45px;
      height: 50px;
      top: 30px;
    }
  }

  // 头部banner
  .top-div {
    position: relative;
    padding-top: 30px;
    .top-bg {
      position: relative;
      width: 100%;
      border-radius: 25px;
    }
    p {
      color: white;
      position: absolute;
      left: 45px;
    }
    .top-t1 {
      font-size: 28px;
      bottom: 100px;
    }
    .top-t2 {
      bottom: 40px;
      font-size: 38px;
    }
  }
  .card-title {
    font-size: 38px;
    font-weight: bold;
    line-height: 90px;
    padding-left: 10px;
  }
  .card-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .card-li {
      background: white;
      border-radius: 25px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 210px;
      width: 160px;
      padding: 30px 30px 40px;
      margin-bottom: 15px;
      .card-icon {
        width: 100px;
      }
      .card-p {
        text-align: left;
        font-size: 30px;
        font-weight: bold;
        color: black;
      }
    }
  }
  .card-box:after {
    content: '';
    width: 160px;
    padding: 30px;
    margin-bottom: 15px;
    height: 0;
    visibility: hidden;
  }
}
</style>
