<template>
  <div class="container1">
    <!-- 顶部标题区 -->
  <!--  <div class="tab-div">
      <img
        class="close-icon"
        src="@/assets/close.png"
        @click="$router.push('/')"
      />
      <span class="tab-title">健康服务</span>
    </div> -->
    <!-- 头部banner -->
    <div class="top-div">
      <img class="top-bg" src="@/assets/bg.png" />
      <p class="top-t1">健康服务</p>
      <p class="top-t2">守护您的健康</p>
    </div>
    <!-- 选项条 -->
    <div class="list-div">
      <router-link to="/GdGhbp">
        <div class="list-li">
          <span>更换表盘</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/GdKpsz">
        <div class="list-li">
          <span>卡片设置</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/GdYltj">
        <div class="list-li">
          <span>音量调节</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/GdLssz">
        <div class="list-li">
          <span>铃声设置</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/GdLdtj">
        <div class="list-li">
          <span>亮度调节</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/GdXpsj">
        <div class="list-li">
          <span>熄屏时间</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/GdRqysj">
        <div class="list-li">
          <span>日期与时间</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/GdPms">
        <div class="list-li">
          <span>屏幕锁</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/GdSssz">
        <div class="list-li">
          <span>手势设置</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/GdYybb">
        <div class="list-li">
          <span>语音播报</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/GdCqycz">
        <div class="list-li">
          <span>重启与重置</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/GdGj">
        <div class="list-li">
          <span>关机</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/GdSj">
        <div class="list-li">
          <span>升级</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
      <router-link to="/GdGysb">
        <div class="list-li">
          <span>关于手表</span>
          <img class="right-icon" src="@/assets/right.png" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
	import "@/assets/css/page_ocss.less"
export default {
  name: 'IndexPage',
}
</script>

